import React from "react";

import Header from "../Header/Header";
import Routers from "../../router/Routers";
import Footer from "../Footer/Footer";

import bg from "../../assets/images/BG.jpg";
import { Col } from "reactstrap";

const Layout = () => {
  return (
    <>
      <div className=" flex justify-center relative">
        <div className="sm:h-full sm:w-full  sm:z-1 fixed " alt="#" />
        <div className="absolute z-10">
          <Header />
          <Routers />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
