// src/AboutUs.js

import React from "react";
import { Container, Row, Col, CardSubtitle } from "reactstrap";
import Subtitle from "./../shared/Subtitle";
import Testimonials from "../components/Testimonial/Testimonials";
import worldImg from "../assets/images/world.png";
import aboutImg from "../assets/images/aboutImg.jpg";
import Card from "../components/Card/Card";

const AboutUs = () => {
  return (
    <div className=" text-black min-h-screen flex flex-col items-center ">
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center">
                  <Subtitle subtitle={"About Us"} />
                </div>

                <p className=" ">
                  Pinnacle Travel has considerable experience in consolidating
                  and implementing large travel accounts. We are aggressively
                  responding to the revolutionary changes in the travel industry
                  and are committed to your travel goals, objectives and success
                  We lead by example with positive, motivated approach and can
                  always be relied on to deliver professional solutions. As your
                  service provider Pinnacle Travel will offer professional
                  service at the best price with a range of unique travel
                  products.
                </p>
              </div>
            </Col>

            <Col lg="6">
              <div className="experience__img">
                <img src={aboutImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/*More About Pinnacle */}

      <section>
        <Subtitle subtitle={"Why Choose Us"} />

        <Card />

        {/* Testimonials*/}

        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Amazing Reviews"} />
              <h2 className="testimonial__title">What our fans say about us</h2>
            </Col>
            <Col lg="12">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default AboutUs;
