import galleryImg01 from "../../assets/images/gallery-01.jpg";
import galleryImg02 from "../../assets/images/gallery-02.jpg";
import galleryImg03 from "../../assets/images/gallery-03.jpg";
import galleryImg04 from "../../assets/images/gallery-04.jpg";
import galleryImg05 from "../../assets/images/gallery-05.jpg";
import galleryImg06 from "../../assets/images/gallery-06.jpg";
import galleryImg07 from "../../assets/images/gallery-07.jpg";
import galleryImg08 from "../../assets/images/gallery-02.jpg";
import galleryImg09 from "../../assets/images/galleryimage9.jpg";
import galleryImg10 from "../../assets/images/galleryimage10.jpg";
import galleryImg11 from "../../assets/images/galleryimage11.jpg";
import galleryImg12 from "../../assets/images/galleryimage12.jpg";
import galleryImg13 from "../../assets/images/galleryimage13.jpg";
import galleryImg14 from "../../assets/images/galleryimage14.jpg";
import galleryImg15 from "../../assets/images/galleryimage15.jpg";
import galleryImg16 from "../../assets/images/galleryimage16.jpg";
import galleryImg17 from "../../assets/images/galleryimage17.jpg";
import galleryImg18 from "../../assets/images/galleryimage18.jpg";
import galleryImg19 from "../../assets/images/galleryimage19.jpg";

import galleryImg21 from "../../assets/images/galleryimage21.jpg";

const galleryImages = [
  galleryImg01,
  galleryImg02,
  galleryImg03,
  galleryImg04,
  galleryImg05,
  galleryImg06,
  galleryImg07,
  galleryImg08,
  galleryImg09,
  galleryImg10,
  galleryImg11,
  galleryImg12,
  galleryImg13,
  galleryImg14,
  galleryImg15,
  galleryImg16,
  galleryImg17,
  galleryImg18,
  galleryImg19,
  galleryImg21,
];

export default galleryImages;
