import React from "react";
import ServiceCard from "./ServiceCard";
import { Col } from "reactstrap";
import weatherImg from "../assets/images/weather.png";
import guideImg from "../assets/images/guide.png";
import customizationImg from "../assets/images/customization.png";
import fareImg from "../assets/images/fare.png";
import vipImg from "../assets/images/vip.png";
import ticket from "../assets/images/ticket.png";
import airportImg from "../assets/images/airport.png";

const servicesData = [
  {
    imgUrl: weatherImg,
    title: ` Travelling planning and organizing`,
    desc: ` Making changes and canceling of reservations for all airlines, rental cars and hotels
Consultation during the compilation and modification of travel guidelines. Organizing business-travel seminars and information management.
 Express ticket delivery service.`,
  },
  {
    imgUrl: guideImg,
    title: `Value Added Services`,
    desc: ` Pinnacle Travel will use our long outstanding good relationship with all airlines 
and hotels for confirmation of waitlisted bookings and l will manage your complete travel account, assist in formulating 
your travel policy, and provide MIS reports, administrating Frequent Traveler 
programes and corporate agreements etc
`,
  },
  {
    imgUrl: fareImg,
    title: "Fare Quotations",
    desc: `Extensive experience in fare construction enables our consultants to lower system quoted 
fares, especially when quoting complicated/multi-segmented itineraries. Pinnacle Travel 
can offer you guaranteed savings on the annual travel expenditure through the available 
unique tools`,
  },
  {
    imgUrl: ticket,
    title: "Promotional Fares",
    desc: `Certain suppliers publish special fares that are quite restrictive. When recommendingany 
such fares, Pinnacle Travel will advise you of the different restrictions such as use of the 
same airline, penalties for changes once the reservation has been made, advance purchase, 
minimum and maximum stay requirements.`,
  },
  {
    imgUrl: vipImg,
    title: "Meet and Greet /VIP Assistance",
    desc: `Pinnacle Travel will provide above service on request to you and their VIP visitors even 
if not ticketed by us at a nominal fee.`,
  },
  {
    imgUrl: airportImg,
    title: "Airport Transfers",
    desc: `Pinnacle Travel will provide transfers to and from the airport at a very minimal fee.`,
  },
];

const ServiceList = () => {
  return (
    <>
      {servicesData.map((item, index) => (
        <Col lg="3" md="6" sm="12" className=" text-white mb-4" key={index}>
          <ServiceCard item={item} />
        </Col>
      ))}
    </>
  );
};

export default ServiceList;
