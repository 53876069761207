import "./App.css";
import Layout from "./components/Layout/Layout";


function App() {

  // console.log('INSIDE APP.JS');
  return <Layout/>
}

export default App;
