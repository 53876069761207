import React from "react";
import { Col, Container, Row } from "reactstrap";

import subtitle from "../../shared/Subtitle";

const Card = () => {
  return (
    <div>
      <subtitle subtitle={"Why Choose Us"} />
      <Container>
        <Row lg="12">
          <div className="testimonial py-4 px-3">
            <div className=" d-flex align-items-center gap-4 mt-3">
              <p className=" outline outline-pink-500 p-4 outline-[0.5px]">
                We are fast, reliable, friendly and offer personally tailored
                holiday vacations and safaris to our guests with top-quality
                lodges, camps luxurious tented camps for that wild safari
                experience. Our safaris are exceptional, try with us and you
                will love East Africa more
              </p>
              <p className=" outline outline-pink-500 p-4 outline-[0.5px]">
                We are fast, reliable, friendly and offer personally tailored
                holiday vacations and safaris to our guests with top-quality
                lodges, camps luxurious tented camps for that wild safari
                experience. Our safaris are exceptional, try with us and you
                will love East Africa more
              </p>
              <p className=" outline outline-pink-500 p-4 outline-[0.5px]">
                We are fast, reliable, friendly and offer personally tailored
                holiday vacations and safaris to our guests with top-quality
                lodges, camps luxurious tented camps for that wild safari
                experience. Our safaris are exceptional, try with us and you
                will love East Africa more
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Card;
