import React from "react";
import { Row, Col } from "reactstrap";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";

const Gallery = () => {
  return (
    <div>
      <Row>
        <Col lg="12">
          <MasonryImagesGallery />
        </Col>
      </Row>
    </div>
  );
};

export default Gallery;
