import React from "react";
import { Link } from "react-router-dom";

const Button = ({ text }) => {
  return (
    <div>
      <button
        type=" button"
        className=" bg-black justify-center hover:text-black hover:bg-white ease-in-out py-2 px-4 text-2xl   text-white   items-center text-center outline outline-1"
      >
        <Link className=" no-underline text-white " to="/dubai">
          {text ? text : "EXPLORE"}
        </Link>
      </button>
    </div>
  );
};

export default Button;
