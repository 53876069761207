import React from "react";
import "../styles/home.css";
import { Container, Row, Col, Table, CardSubtitle } from "reactstrap";
import cruise from "../assets/images/cruise.png";
import qatar1 from "../assets/images/qatar1.png";
import outsideairport from "../assets/images/outsideairport.png";
import insideairport from "../assets/images/insideairport.png";
import qatar2 from "../assets/images/qatar2.png";
import doha1 from "../assets/images/doha1.png";
import doha2 from "../assets/images/doha2.png";
import breakfast from "../assets/images/breakfast.png";
import newyear2 from "../assets/images/newyear2.png";
import f1theme from "../assets/images/f1theme.png";
import f1theme2 from "../assets/images/f1theme2.png";
import f1theme3 from "../assets/images/f1theme3.png";
import shopping from "../assets/images/shopping.png";

import Subtitle from "./../shared/Subtitle";
import SearchBar from "./../shared/SearchBar";
import ServiceList from "../services/ServiceList";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList.jsx";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Testimonials from "../components/Testimonial/Testimonials";
import CommonSection from "../shared/CommonSection.jsx";
import NewsLetter from "../shared/Newsletter";
import Logos from "../components/logos/Logos.jsx";

const Dubai = () => {
  return (
    <div className=" ">
      <section className=" ">
        <Container>
          <Row>
            <Col lg="12">
              <div>
                <div className="hero__subtitle d-flex align-items-center">
                  <Subtitle subtitle={"DUBAI CRUISE"} />
                </div>
                <h1>
                  Experience a holiday like no other this{" "}
                  <span className="hightlight"> festive season</span>
                </h1>
                <p>
                  A cruise to Dubai offers an unparalleled experience blending
                  modern luxury with ancient traditions. As one of the world's
                  most opulent cities, Dubai is renowned for its futuristic
                  skyline, featuring iconic structures like the Burj Khalifa,
                  the tallest building in the world, and the sail-shaped Burj Al
                  Arab hotel. When cruising to Dubai, passengers can look
                  forward to a mix of relaxation and adventure.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ==================== HERO SECTION START ====================== */}
      <section className=" -mt-12">
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Itinerary"} />

              <Table responsive bordered className="  font-bold">
                <thead>
                  <tr className=" text-blue-400">
                    <th>Day</th>
                    <th>Destination</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Abu Dhabi, United Arab Emirates</td>
                    <td>Wed Dec 18, 2024</td>
                    <td>Depart 09:00 PM</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Sir Bani Yas, United Arab Emirates</td>
                    <td>Thu Dec 19, 2024</td>
                    <td>08:00 AM - 06:00 PM</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Dubai, United Arab Emirates</td>
                    <td>Fri Dec 20, 2024</td>
                    <td>09:00 AM - 11:59 PM</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Dubai, United Arab Emirates</td>
                    <td>Sat Dec 21, 2024</td>
                    <td>12:01 AM - 07:00 PM</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Doha, Qatar</td>
                    <td>Sun Dec 22, 2024</td>
                    <td>08:00 AM - 06:00 PM</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Bahrain, Bahrain</td>
                    <td>Mon Dec 23, 2024</td>
                    <td>08:00 AM - 07:00 PM</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Day At Sea</td>
                    <td>Tue Dec 24, 2024</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Abu Dhabi, United Arab Emirates</td>
                    <td>Wed Dec 25, 2024</td>
                    <td>Arrive 07:00 AM</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ========== FEATURED TOUR SECTION START ========== */}

      <Container>
        <Col lg="12">
          <Subtitle subtitle={"Rates for 3 Doubles (6 Pax)"} />

          <Table responsive bordered className="  font-bold">
            <thead>
              <tr className=" text-blue-400">
                <th>Cabin Type</th>
                <th>Rate</th>
                <th>Inclusive of Economy Ticket</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Inside</td>
                <td>$3,296.00</td>
                <td>$8,726.00</td>
              </tr>
              <tr>
                <td>Outside</td>
                <td>$3,825.00</td>
                <td>$9,255.00</td>
              </tr>
              <tr>
                <td>Balcony</td>
                <td>$4,385.00</td>
                <td>$9,815.00</td>
              </tr>
              <tr>
                <td>Suite</td>
                <td>$11,055.00</td>
                <td>$16,485.00</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Container>
      {/* ========== FLIGHT OPTIONS START ========== */}

      <Container className=" mt-6  font-bold">
        <Col lg="12">
          <h2 className=" font-bold">Flight Options</h2>
          <h4>Etihad</h4>
          <p>EY 768 17DEC NBO-AUH 18:10 - 00:25 18DEC</p>
          <p>EY 767 25DEC AUH-NBO 09:05 - 13:10 25DEC</p>
          <h4>Qatar Airways</h4>
          <p>QR1342 18DEC NBO-DOH 01:35 - 07:05 18DEC</p>
          <p>QR1044 18DEC DOH-AUH 08:45 - 10:50 18DEC</p>
          <p>QR1055 25DEC AUH-DOH 23:30 - 23:40 25DEC</p>
          <p>QR1335 26DEC DOH-NBO 02:00 - 07:35 26DEC</p>
        </Col>
      </Container>

      {/* ========== CRUISES TOUR SECTION  =========== */}

      <section>
        <Container className="">
          <Row>
            <Col lg="12">
              <div className="hero__subtitle d-flex align-items-center"></div>
              <h1 className="underline text-blue-400">
                4 NIGHTS | MSC CRUISES: MSC EURIBIA
              </h1>
              <h4 className=" font-bold"> 27th December 2024</h4>
              <p>
                Departure JKIA at 1645hrs and arrive at Dubai International
                airport at 2245hrs. Meet & greet by our representative then
                transfer to the hotel for your check-in and overnight
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="">
                <img class src={insideairport} alt="#" />
              </div>
            </Col>
            <Col lg="6">
              <div className="counter__wrapper d-flex align-items-center gap-5">
                <img src={outsideairport} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/**row 1 */}

      <section>
        <Container className="">
          <Row>
            <Col lg="12">
              <div className="hero__subtitle d-flex align-items-center"></div>

              <h4 className=" font-bold"> 28th December 2024</h4>
              <p>
                Enjoy breakfast then transfer to the port for your cruise
                embarkation. Check-in then enjoy dinner and overnight on board.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <img className=" object-fill w-[100%]" src={cruise} alt="#" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/**row 2 */}
      <section>
        <Container className="">
          <Row>
            <Col lg="12">
              <h4 className=" font-bold"> 29th December 2024</h4>
              <p>
                Spend full day in Doha, Qatar then return to the ship for dinner
                and overnight.{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="">
                <img class src={qatar1} alt="#" />
              </div>
            </Col>
            <Col lg="6">
              <div className="counter__wrapper d-flex align-items-center gap-5">
                <img src={qatar2} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ========== EXPERIENCE SECTION START ============ */}
      <Container className="">
        <Row>
          <Col lg="12">
            <h4 className=" font-bold"> 30th December 2024</h4>
            <p>
              Full day spent Bahrain then return to the ship for dinner and
              overnight.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <div className="">
              <img class src={doha1} alt="#" />
            </div>
          </Col>
          <Col lg="6">
            <div className="counter__wrapper d-flex align-items-center gap-5">
              <img src={doha2} />
            </div>
          </Col>
        </Row>
        <Col lg="12">
          <p className=" mt-6">
            Bahrain is an archipelago of This location means there are
            essentially two seasons: hot summers and relatively mild winters.
            Discover the appeal of experiencing both times of year in this
            unique and distinctive destination.
          </p>
          <p>
            Bahrain has a long cultural legacy. The capital city, Manama, was
            named Capital of Asian Tourism in 2015 and Capital of Arab Tourism
            in 2013. It was also nominated as the Capital of Arab Culture in
            2012.
          </p>
        </Col>
      </Container>

      {/** */}
      <Container className="">
        <Row>
          <Col lg="12">
            <h4 className=" font-bold"> 31st December December 2024</h4>
            <p>Full day spent cruising and usher in the new year at sea</p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="counter__wrapper d-flex align-items-center gap-5">
              <img
                className="  object-fill w-[100%] max-h-[500px]"
                src={newyear2}
              />
            </div>
          </Col>
        </Row>
        <Col lg="12">
          <p className=" mt-6">
            <span className=" font-bold">MSC Euribia</span> is named after the
            <span className=" font-bold">ancient goddess Eurybia</span> who
            harnessed the winds, weather and constellations to master the seas,
            furthering the vision of the ship to master the deployment of
            state-of-the-art technology to minimise its impact on the precious
            marine ecosystem.
          </p>
          <p>
            The ship is also empowered with striking new features. Not only do
            the decks boast numerous original works of art, for the first time
            the hull itself is a visible masterpiece. On this unique ship where
            art meets innovation.
          </p>
          On this unique ship where art meets innovation.
        </Col>
      </Container>

      {/** */}
      <Container className="">
        <Row>
          <Col lg="12">
            <h4 className=" font-bold">1 st January 2025</h4>
            <p>
              Disembark from your cruise then transfer to Ferrari world for a
              full day of adrenaline activities. Later on, transfer to your
              hotel for check-in and overnight.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="counter__wrapper d-flex align-items-center gap-5">
              <img
                className=" w-[100%]  object-fill max-h-[500px]"
                src={f1theme}
              />
            </div>
          </Col>
        </Row>
        <section className=" mt-2">
          <Row lg="12">
            <Col lg="">
              <div className="">
                <img class src={f1theme3} alt="#" />
              </div>
            </Col>
            <Col lg="">
              <div className="counter__wrapper d-flex align-items-center gap-5">
                <img src={f1theme2} />
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      {/** */}

      <Container className="">
        <Row>
          <Col lg="12">
            <h4 className=" font-bold">2nd January 2025</h4>
            <p>
              Have breakfast and enjoy the morning at leisure then afternoon
              departure for your desert safari caravan with dinner. Return to
              the hotel for overnight.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="counter__wrapper d-flex align-items-center gap-5">
              <img
                className=" w-[100%]  object-fill max-h-[500px]"
                src={breakfast}
              />
            </div>
          </Col>
        </Row>
        <div className=" mb-4"></div>
        <Row>
          <Col lg="12">
            <p>
              Find all you need, to immerse yourself in the ultimate Arabian
              experience, with our incredible Dubai Caravan Safari. Discover a
              bygone era as you wander along the windswept dunes in our exciting
              Desert Caravan Safari, followed by a wide array of buffet dinner
              options. We indulge our guests in the opportunity to be pampered
              by our stunningly recreated Caravanserai, with a modern luxury
              twist, in an authentic desert setting
            </p>
          </Col>
        </Row>
      </Container>

      <section>
        <Container>
          <Row>
            <Col lg="12">
              <h4 className=" font-bold">2nd January 2025</h4>
              <p>After breakfast, enjoy full day at leisure and shopping.</p>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="counter__wrapper d-flex align-items-center gap-5">
                <img
                  className=" w-[100%]  object-fill max-h-[500px]"
                  src={shopping}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/** */}

      <Container>
        <Row>
          <Col lg="12">
            <h4 className=" font-bold">4 th January 2025</h4>
            <p>
              Enjoy your breakfast and check-out then transfer to the airport
              for home-bound flight.
            </p>
          </Col>
        </Row>
      </Container>

      {/* ========== EXPERIENCE SECTION END ============== */}

      {/* ========== GALLERY SECTION START ============== */}

      {/* ========== GALLERY SECTION END ================ */}

      {/* ========== TESTIMONIAL SECTION START ================ */}

      {/*================ LOGOS================ */}

      {/* ========== CHATBOX SECTION START ============== */}
    </div>
  );
};

export default Dubai;
