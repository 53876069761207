import React from "react";
import diversey from "../clientlogos/diversey.jpg";
import kaplan from "../clientlogos/kaplan.png";
import publishers from "../clientlogos/publishers.jpg";
import ncba from "../clientlogos/ncba.jpg";
import raxio from "../clientlogos/raxio.png";
import solex from "../clientlogos/solex.png";
import surgi from "../clientlogos/surgipath.png";
import transcentury from "../clientlogos/transcentury.jpg";
import telkom from "../clientlogos/telkom.png";
import { Row, Col, Container } from "reactstrap";
import Subtitle from "../../shared/Subtitle";

const Logos = () => {
  return (
    <div className="  w-[100%] items-center">
      <section className="  ">
        <Container>
          <Row lg="12">
            <Col lg="12">
              <Subtitle subtitle={"Our Clients"} />
              <div className="testimonial    py-4 px-3">
                <div className="d-flex items-center justify-right k w-[100vw]  gap-4  mt-3">
                  <img
                    className="object-contain md:h-[100px] md:w-[100px] h-[150px] w-[50px]"
                    src={transcentury}
                    alt="#"
                  />
                  <img
                    className=" object-contain  md:h-[70px] md:w-[70px] h-[50px] w-[50px]"
                    src={diversey}
                    alt="#"
                  />
                  <img
                    className="object-contain  md:h-[70px] md:w-[70px] h-[50px] w-[50px]"
                    src={kaplan}
                    alt="#"
                  />
                  <img
                    className=" object-contain  md:h-[70px] md:w-[70px] h-[50px] w-[50px]"
                    src={publishers}
                    alt="#"
                  />
                  <img
                    className=" object-contain  md:h-[70px] md:w-[70px] h-[50px] w-[50px]"
                    src={ncba}
                    alt="#"
                  />
                  <img
                    className="object-contain  md:h-[70px] md:w-[70px] h-[50px] w-[50px]"
                    src={surgi}
                    alt="#"
                  />
                  <img
                    className="object-contain  md:h-[70px] md:w-[70px] h-[50px] w-[50px]"
                    src={solex}
                    alt="#"
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/**internmeent */}
        </Container>
      </section>
    </div>
  );
};

export default Logos;
